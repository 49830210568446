// This is a one off request for the NPR portal EXT-370

import React from 'react';
import Script from 'next/script';

export type PodsightsScriptProps = {
  sdkKey: string;
};

function createScriptContent(sdkKey: string) {
  return `
    (function(w, d){
     var id='pdst-capture', n = 'script';
     if (!d.getElementById(id)){
       w.pdst = w.pdst || function() {(w.pdst.q = w.pdst.q || []).push(arguments);};
       var e = d.createElement(n); e.id = id; e.async=1;
       e.src = 'https://cdn.pdst.fm/ping.min.js';
       var s = d.getElementsByTagName(n)[0];
       s.parentNode.insertBefore(e, s);
     }
     w.pdst('conf', { key: '${sdkKey}' });
     w.pdst('view');
    })(window, document);
  `;
}

export default function PodsightsScript({ sdkKey }: PodsightsScriptProps) {
  if (!sdkKey) return null;

  return (
    <Script
      id="podsights-pixel-script"
      strategy="afterInteractive"
      data-testid="podsights-pixel-script"
      dangerouslySetInnerHTML={{ __html: createScriptContent(sdkKey) }}
    />
  );
}
