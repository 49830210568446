import { toDate } from 'date-fns-tz';
import React, { useContext } from 'react';
import { Config } from './interfaces';
import { Asset } from './storyblok.interfaces';

export const ConfigContext = React.createContext(null);

export function useConfig(): Config {
  return useContext(ConfigContext) as unknown as Config;
}

interface StoryblokConfigStory {
  content: {
    _uid: string;
    component: string;
    promotion_ref: string;
    promotion_logo?: Asset;
    promotion_end_date?: string;
    campaign_ref?: string;
    podsights_sdk_key: string | null;
  };
}

export function convertStoryblokContentToConfig({ content }: StoryblokConfigStory): Config {
  const { promotion_end_date } = content;

  return {
    promotion: {
      ref: content.promotion_ref,
      logo: content.promotion_logo,
      campaign: content.campaign_ref || '',
      endDate: !promotion_end_date ? null : toDate(promotion_end_date, { timeZone: 'UTC' }).toJSON(),
    },
    podsightsKey: content?.podsights_sdk_key?.trim() || null,
  };
}
