import Analytics, { AnalyticsInstance } from 'analytics';
import amplitudePlugin from '@analytics/amplitude';

let analytics: AnalyticsInstance;

export function getAnalyticsClient(): AnalyticsInstance {
  if (analytics) return analytics;

  analytics = Analytics({
    app: 'portals-enrollment-client',
    plugins: [
      amplitudePlugin({
        apiKey: process.env.NEXT_PUBLIC_EVGO_AMPLITUDE_API_KEY || '',
      }),
    ],
  });

  return analytics;
}

export const events = {
  viewPage: 'view page',
  clickRegisterButton: 'click register button',
  clickLoginButton: 'click login button',
};

export function getUrlParams(search: string): {
  error: string;
  message: string;
  promotionCode: string;
  promotionType: string;
  origin: string;
} {
  const urlParams = new URLSearchParams(search);
  const error = urlParams.get('error') || '';
  const message = urlParams.get('message') || '';
  const promotionCode = urlParams.get('promotionCode') || '';
  const promotionType = urlParams.get('promotionType') || '';
  const origin = urlParams.get('origin') || '';
  return { error, message, promotionCode, promotionType, origin };
}
