import { ApolloClient, split, HttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import fetch from 'isomorphic-fetch';
import ws from 'isomorphic-ws';
import packageInfo from '../../package.json';
import firebaseInstance from '../../lib/firebase';
import { getAuth } from 'firebase/auth';
import resolvers from './resolvers';

/**
 * Apollo cache
 * @type {Object}
 */
const cache = new InMemoryCache();

/**
 * Apollo server link
 * @type {Object}
 */
const httpLink = new HttpLink({ uri: process.env.NEXT_PUBLIC_GRAPHQL_API_HOST, fetch });

const wsLink = new WebSocketLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_WS_HOST as string,
  options: {
    reconnect: true,
    lazy: true,
  },
  webSocketImpl: ws,
});

// @ts-ignore
wsLink.subscriptionClient.on('error', (e) => {
  console.log(e);
});

// @ts-ignore
wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () => {
  // @ts-ignore
  return wsLink.subscriptionClient.maxConnectTimeGenerator.max;
};

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const authLink = setContext(async (_, { headers }) => {
  // grab the user auth token
  const auth = getAuth(firebaseInstance);
  const token = await auth.currentUser?.getIdToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const { name, version } = packageInfo;
const apolloClient = new ApolloClient({
  cache,
  link: from([authLink, link]),
  resolvers,
  name,
  version,
});

export default apolloClient;
