import * as Sentry from '@sentry/nextjs';
import getSentryEnvironment from './sentry.get-environment';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  environment: getSentryEnvironment(),
});
