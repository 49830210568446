const config = {
  'extend-qa': 'qa',
  'extend-stg': 'staging',
  'extend-prod': 'production',
  'extend-dev': 'development',
};

export default function getSentryEnvironment() {
  return config[process.env.NEXT_PUBLIC_APP_NAME] || 'development';
}
