import { isAfter } from 'date-fns';
import { toDate } from 'date-fns-tz';

export function isPromotionExpired(date: string | null) {
  if (!date) {
    return false;
  }

  const now = new Date();

  return isAfter(now, toDate(date));
}
