import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import firebaseInstance from '../lib/firebase';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ApolloProvider } from '@apollo/client';
import client from '../src/apollo';
import { ConfigContext } from '../lib/config';
import '../styles/globals.css';
import { isPromotionExpired } from '../src/helpers/isPromotionExpired';
import { Config } from '@/lib/interfaces';
import { events, getAnalyticsClient, getUrlParams } from '@/lib/analytics';
import PodsightsScript from '@/components/PodsightsScript';
import { useState } from 'react';
import { GTM_ID, pageview } from '@/lib/gtm';
import Head from 'next/head';

function MyApp({ Component, pageProps }: any) {
  const auth = getAuth(firebaseInstance);
  const router = useRouter();
  const config = pageProps.config as Config;
  const [userLoading, setUserLoading] = useState(true);
  const [firebaseUser, firebaseLoading] = useAuthState(auth);

  useEffect(() => {
    if (!firebaseUser && !firebaseLoading) {
      signInAnonymously(auth)
        .finally(() => {
          setUserLoading(false);
        })
        .catch((error) => {
          console.log('--------> Error attemping sign into Firebase', error);
        });
    }
  }, [auth, firebaseUser, firebaseLoading]);

  useEffect(() => {
    const analytics = getAnalyticsClient();
    const trackPageChange = (url: string) => {
      const { error, message, promotionCode, promotionType, origin } = getUrlParams(window.location.search);
      analytics.track(events.viewPage, {
        url,
        error,
        message,
        promotionCode,
        promotionType,
        origin,
        host: window.location.host,
        pathname: window.location.pathname,
        search: window.location.search,
      });
    };
    router.events.on('routeChangeComplete', trackPageChange);
    router.events.on('routeChangeComplete', pageview);

    return () => {
      // unsubscribe from the event with the `off` method
      router.events.off('routeChangeComplete', trackPageChange);
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events, router.query]);

  useEffect(() => {
    const isPreviewMode = router.asPath.includes('_storyblok');
    const expired = isPromotionExpired(pageProps.config.promotion.endDate);

    if (expired && !isPreviewMode) {
      router.push({ pathname: `/expired` });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!firebaseUser && userLoading) return <></>;

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${GTM_ID}');
            `,
        }}
      />
      <ApolloProvider client={client}>
        <ConfigContext.Provider value={pageProps.config}>
          <Component {...pageProps} />
        </ConfigContext.Provider>
      </ApolloProvider>
      {config.podsightsKey && <PodsightsScript sdkKey={config.podsightsKey} />}
    </>
  );
}

export default MyApp;
